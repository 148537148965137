// Generated by Framer (508aa67)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["nBe2KnLDJ", "gtwLBYuHG"];

const serializationHash = "framer-LHS1d"

const variantClassNames = {gtwLBYuHG: "framer-v-192ury3", nBe2KnLDJ: "framer-v-1t8evnh"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "nBe2KnLDJ", "Variant 2": "gtwLBYuHG"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "nBe2KnLDJ"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "nBe2KnLDJ", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnterlfir2j = activeVariantCallback(async (...args) => {
setVariant("gtwLBYuHG")
})

const onMouseLeave166eeww = activeVariantCallback(async (...args) => {
setVariant("nBe2KnLDJ")
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1t8evnh", className, classNames)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"nBe2KnLDJ"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onMouseEnter={onMouseEnterlfir2j} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({gtwLBYuHG: {"data-framer-name": "Variant 2", onMouseEnter: undefined, onMouseLeave: onMouseLeave166eeww}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.h1 style={{"--font-selector": "R0Y7SW50ZXIgVGlnaHQtNzAw", "--framer-font-family": "\"Inter Tight\", \"Inter Tight Placeholder\", sans-serif", "--framer-font-size": "77px", "--framer-font-weight": "700", "--framer-letter-spacing": "-1px", "--framer-line-height": "100%", "--framer-text-color": "var(--extracted-gdpscs, var(--token-9e3d6e90-54f3-4aff-b775-d18b5ecff410, rgb(255, 255, 255)))"}}>@kashdhanda.com</motion.h1></React.Fragment>} className={"framer-tetkqj"} data-framer-name={"hello@kazarov.com"} fonts={["GF;Inter Tight-700"]} layoutDependency={layoutDependency} layoutId={"unEAjWomf"} style={{"--extracted-gdpscs": "var(--token-9e3d6e90-54f3-4aff-b775-d18b5ecff410, rgb(255, 255, 255))"}} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-LHS1d.framer-14uibn3, .framer-LHS1d .framer-14uibn3 { display: block; }", ".framer-LHS1d.framer-1t8evnh { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 0px; height: 87px; justify-content: center; overflow: hidden; padding: 0px 0px 10px 0px; position: relative; width: 710px; }", ".framer-LHS1d .framer-tetkqj { flex: 1 0 0px; height: 1px; position: relative; white-space: pre-wrap; width: 100%; word-break: break-word; word-wrap: break-word; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-LHS1d.framer-1t8evnh { gap: 0px; } .framer-LHS1d.framer-1t8evnh > * { margin: 0px; margin-bottom: calc(0px / 2); margin-top: calc(0px / 2); } .framer-LHS1d.framer-1t8evnh > :first-child { margin-top: 0px; } .framer-LHS1d.framer-1t8evnh > :last-child { margin-bottom: 0px; } }", ".framer-LHS1d.framer-v-192ury3 .framer-tetkqj { left: 0px; order: 0; position: absolute; top: -121px; white-space: pre; width: auto; z-index: 1; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 87
 * @framerIntrinsicWidth 710
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"gtwLBYuHG":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerbVSoTdmcX: React.ComponentType<Props> = withCSS(Component, css, "framer-LHS1d") as typeof Component;
export default FramerbVSoTdmcX;

FramerbVSoTdmcX.displayName = "Text Cycle Copy";

FramerbVSoTdmcX.defaultProps = {height: 87, width: 710};

addPropertyControls(FramerbVSoTdmcX, {variant: {options: ["nBe2KnLDJ", "gtwLBYuHG"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerbVSoTdmcX, [{explicitInter: true, fonts: [{family: "Inter Tight", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/intertight/v7/NGSnv5HMAFg6IuGlBNMjxJEL2VmU3NS7Z2mj6AiqWSRToK8EPg.woff2", weight: "700"}]}], {supportsExplicitInterCodegen: true})